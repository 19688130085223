.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: linear-gradient(
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.3)
      ),
      url("../public/img/bg.webp");
    /* background: linear-gradient(
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.3)
      ),
      url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"); */
    background-size: cover;
    background-position: center;
  }
  
  form{
      background-color: white;
      padding: 20px 30px;
      border-radius: 10px;
  }
  
  h1{
      color: rgb(77, 1, 77);
      text-align: center;
      font-size: large;
      margin-bottom: 10px;
  }

  .cssfordropdown{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
  }
  
  .required-asterisk {
    color: red;
    margin-left: 5px;
    display: inline;
  }
  .formargin{
    margin-top: 20px;
  }

  .ltvdesign{
    text-align: center;
  }

  .hrtop{
    margin-bottom: 20px;
  }


  .alignStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ResStyle{
    margin-top: 10px;

  }



.whatsappStyle{
  height: 50px;
  width: 40px;
  cursor: pointer; /* Makes the button clickable */


}



.hrbuttom{
  margin-top: 20px;
  margin-bottom: 20px;
}
.emitext{
  font-style: italic;
  font-weight: 800;
}
.forgap{
  margin-bottom: 5px;
}
.tgged{
  font-style: italic;
  font-weight: 300;
  font-size: small;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
  button{
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: rebeccapurple;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 15px;
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
  }

  .feedbackcss{
    margin-bottom: 20px;
    cursor: pointer;
  }

  .non-editable-input {
    background-color: #f0f0f0; /* Example color for the non-editable input */
    color: #000000; /* Example text color for the non-editable input */
  }
  

  /* .forRadioButton{
    display: flex;
    flex-direction: column;
    
  }
   */

   .forRadioButton label {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .forRadioButton input {
    margin-right: 5px;
  }
  

  .headdingCass{
    /* background-color: aqua; */
    font-style: italic;
    font-size: xx-small;
    text-align: center;
    margin-bottom: 10px;
  }