@import "../app.scss";

.contact {
  position: absolute;
  background-color: rgb(236, 233, 233);
  height: 100%;
  max-width: 1366px;
  margin: auto;
  display: flex;
  align-items: center;

  .forclose{
    position: absolute;
    right: 10px;
    top: 10px;
  }

  @include mobile {
    height: 90%;
    width: 100%;
    // padding: 10px;
    flex-direction: column;
  }
 
  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include mobile {
      gap: 20px;
      text-align: center;
      align-items: center;
      margin-top: 70px;
    }

    h1 {
      font-size: 100px;
      line-height: 88px;

      @include mobile {
        font-size: 36px;
      }
    }

    span {
      font-weight: 300;
    }
  }

  .formContainer {
    flex: 1;
    position: relative;
    
    @include mobile {
      padding: 50px;
      width: 100%;
    }


    .phoneSvg {
      stroke: orange;
      position: absolute;
      margin: auto;
      z-index: -1;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input,
      textarea {
        padding: 20px;
        background-color: transparent;
        border: 1px solid rgb(114, 99, 99);
        color: black;
        border-radius: 5px;

        @include mobile {
          padding: 10px;
        }
      }

      button {
        padding: 20px;
        border: none;
        background-color: orange;
        cursor: pointer;
        font-weight: 500;
        @include mobile {
          padding: 10px;
        }
      }
    }


       /* Loading Spinner Styles */
       .loading {
        position: absolute;
        top: 50%;
        left: 42%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        border: 6px solid #f3f3f3;
        border-top: 6px solid orange;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        z-index: 999;  /* Make sure the spinner stays on top */
      }
  
      /* Keyframes for spinning animation */
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
  }


  @include mobile {
   position: absolute;
   top: 0px;
   margin: 10px;


  }
}